<template>
    <div id="onlineProtocol">
        <div class="title">线上协议</div>
    </div>
</template>

<script>
export default {

    mounted(){
        document.title = "代扣系统-线上协议";
    }
}
</script>

<style lang="scss" scoped>
#onlineProtocol{
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    min-height: 85vh;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    .title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 30px;
    }
}
</style>